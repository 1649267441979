html {
  position: relative;
  min-height: 100%;
  /* overflow-y: scroll; */
}

body {
  /*padding: 0px 0px 0px 5px;*/
  font-size: 0.9rem;
  color: #4c4c4c;
  /*background-image: url('../assets/img/bg.jpg')*/
}

.container-fluid {
  margin-bottom: 200px;
}

.yellow {
  background: yellow;
}

.card,
.form-control,
.alert {
  border-radius: 5px !important;
}

.alert-trim {
  display: inline-block;
}

.underline {
  text-decoration: underline;
}

.card {
  margin-bottom: 20px;
  border: none !important;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%) !important;
}

.card-borders {
  border: 1px solid rgba(0, 0, 0, 0.125) !important;
  border-radius: 5px;
  box-shadow: none !important;
}

.mainDarkBlueCard {
  border-radius: 5px !important;
  background-color: #07184D;
  box-shadow: 0px 4px 8px 0px rgb(0 0 0 / 20%) !important;
  color: #FFFFFF;
}

.mainDarkBlueCard * {
  color: inherit;
}

.cursorPointer {
  cursor: pointer;
}

.cursorPointerHover {
  cursor: pointer;
}

.cursorPointerHover:hover {
  opacity: 0.6;
}

.cursorDisabled {
  cursor: not-allowed;
}

.locationNetworkFailureText {
  font-size: 0.7em;
}

/*
a {
    color: #4c4c4c;
}
*/

/*div.mat-form-field-flex,*/
div.mat-form-field-outline-start,
div.mat-form-field-outline-gap,
div.mat-form-field-outline-end,
div.mat-checkbox-inner-container {
  background: #ffffff;
}

div.mat-form-field-infix {
  padding: 7px !important;
}

.mat-form-field-appearance-outline.mat-focused .mat-form-field-outline {
  color: #1d83e9 !important;
}

.mat-checkbox-checked.mat-accent .mat-checkbox-background {
  background-color: #1d83e9 !important;
}

.form-field-container {
  display: flex;
  flex-direction: column;
}

/*Dropdown button color*/
button.dropdown-toggle {
  background: #ffffff;
}

/*Global background color*/
.globalNav,
.nav-container,
.globalContent,
.col-md-12,
.container-fluid {
  background: #f5f7f8 !important;
}

/*
.globalContent {
    margin-left: 40px;
    margin-right: 40px;
}
*/

.card-body-dark {
  padding: 0 25px 25px 25px;
  background-color: rgba(0, 0, 0, 0.06);
}

.card-body-white {
  padding: 0 25px 0 25px;
  background-color: #ffffff;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  margin-bottom: 5px;
  padding-bottom: 10px;
}

.white-header-text {
  position: relative;
  top: 25%;
}

.card-body-container {
  padding: 25px;
  border-radius: 5px;
  background-color: #ffffff;
  -webkit-box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.3);
  box-shadow: -4px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.card-header {
  background-color: #ffffff;
  border-radius: 5px !important;
}

.card-header-dark {
  padding-left: 30px;
  background-color: rgba(0, 0, 0, 0.06);
}

.card-header-white {
  padding-left: 30px;
  background-color: #ffffff;
  height: 50px !important;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.header-underline {
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.body-overline {
  padding-top: 5px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.card-body-shadows {
  box-shadow: -4px 4px 8px 0px rgb(0 0 0 / 30%);
}

.white-header-xmark-icon {
  margin-right: 15px !important;
  position: relative !important;
  top: -12px !important;
}

.white-header-icon {
  position: relative;
  top: -12px;
}

.mat-step-header .mat-step-icon-selected,
.mat-step-header .mat-step-icon-state-done,
.mat-step-header .mat-step-icon-state-edit {
  background-color: #1d83e9 !important;
}

span.mat-select-placeholder,
span.mat-select-value-text {
  position: relative;
  top: -2px;
}

@media all and (max-width: 990px) {
  .card-body-container {
    padding: 15px;
  }

  .card-body-dark {
    padding: 0 15px 15px 15px;
  }

  .card-header-dark {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}

@media all and (max-width: 640px) {
  .card-body-container {
    padding: 12px;
    -webkit-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
    -moz-box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
    box-shadow: -2px 2px 4px 0 rgba(0, 0, 0, 0.3);
  }

  .card-body-dark {
    padding: 0 12px 12px 12px;
  }

  .card-header-dark {
    padding-top: 8px;
    padding-bottom: 8px;
  }
}

a:hover,
a:active,
a:focus {
  text-decoration: none;
  color: #4c4c4c;
}

/* Everon logo styles */
.loadContainer {
  width: 250px;
  margin: auto;
  text-align: center;
  margin-top: 50px;
}

.loadContainer>img {
  height: 200px;
}

.logoSmall {
  height: 80px;
  width: 80px;
}

.smallLoader {
  display: block;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  outline: 1px solid transparent;
  margin-right: 5px;
  border: 5px solid #fff;
  border-top: 5px solid #31b0d5;
  width: 20px;
  height: 20px;
}

.fadeLoader {
  animation: fade-loop 3s linear infinite;
}

.centerLoader {
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.verticalCenterLoader {
  margin-top: auto;
  margin-bottom: auto;
  display: block;
}

.fullWidth {
  width: 100%;
}

.hrCompact {
  margin: 5px;
  padding: 0px;
}

.form-control::-moz-placeholder {
  opacity: 0.5;
}

.form-control:-ms-input-placeholder {
  opacity: 0.5;
}

.form-control::-webkit-input-placeholder {
  opacity: 0.5;
}

.form-control.ng-invalid:not(.ng-untouched) {
  box-shadow: 0 0 0 0.2rem rgba(232, 65, 59, 0.7);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fade-loop {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.2;
  }

  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
  }
}

.fadeOut {
  animation-name: fadeOut;
  animation-duration: 0.5s;
  animation-timing-function: ease-out;
  animation-fill-mode: forwards;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

/* BUTTONS */
.btn {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 7px 20px 7px 20px;
  border-radius: 5px !important;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
}

.btn-small {
  font-size: 16px !important;
  font-weight: 500 !important;
  padding: 0 15px 0 15px !important;
  border-radius: 5px !important;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%);
}

.dropDownBtn {
  font-size: 0.875rem !important;
  font-weight: 400 !important;
  padding: 0.25rem 0.5rem;
  border-radius: 5px !important;
  color: black;
  background-color: #ffffff;
  border: 1px solid #ced4da;
  box-shadow: none !important;
}

.btn>span::before {
  padding-right: 7px;
}

.btn>span>span::before {
  padding-right: 7px;
}

/*Primary buttons*/
.btn-primary,
.btn-primary:disabled {
  color: #ffffff;
  background-color: #1241cd;
  border: none;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.btn-primary:hover:not([disabled]),
.btn-primary:active:not([disabled]),
.btn-primary:focus:not([disabled]) {
  color: #ffffff;
  background-color: #0d2c8d;
  border: none;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}

.primaryBtn {
  font-weight: 500;
  color: #ffffff;
  background-color: #1241cd !important;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.btnText16px {
  font-size: 16px !important;
}

.primaryBtn:disabled,
.primaryBtn[disabled] {
  background-color: #dadada !important;
  color: #4e4e4e;
}

.primaryBtn:hover:not([disabled]) {
  background-color: #0d2c8d !important;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}

/*Secondary buttons*/
.secondaryBtnWhiteBg {
  font-weight: 500;
  color: #4e4e4e;
  background-color: #f5f7f8;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.secondaryBtnGrayBg {
  font-weight: 500;
  color: #4e4e4e;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.secondaryBtnWhiteBg:hover,
.secondaryBtnGrayBg:hover {
  background-color: #ecedf0;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}

.confirmBtn {
  font-weight: 500;
  color: #ffffff;
  background-color: #089C3B;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.confirmBtn:disabled {
  background-color: #707070;
  color: #ffffff;
}

.confirmBtn:not(:disabled):hover {
  background-color: #006400;
  color: #ffffff;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}


.btn-outline-primary,
.btn-outline-primary:disabled {
  color: #4e4e4e;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.btn-outline-primary:hover:not([disabled]),
.btn-outline-primary:active:not([disabled]),
.btn-outline-primary:focus:not([disabled]) {
  color: #4e4e4e;
  background-color: #ecedf0;
  border: none;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}

/*Used as secondary button against white background*/
.btn-outline-dark {
  color: #4e4e4e;
  background-color: #f5f7f8;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
  border: none;
}

.btn-outline-dark:hover:not([disabled]),
.btn-outline-dark:active:not([disabled]) {
  color: #4e4e4e;
  background-color: #ecedf0;
  border: none;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 25%);
}

.btn-outline-dark:focus:not([disabled]) {
  box-shadow: none;
}

.gray-bg,
.gray-bg:disabled {
  background-color: #f5f7f8;
}

.white-bg {
  background-color: #ffffff !important;
  background: #ffffff !important;
}

/*Tertiary buttons*/
.tertiaryBtn {
  font-weight: 500;
  color: #52526e;
  padding: 7px 20px;

  background-color: #ffffff;
  border-radius: 5px;
  border: none;
}

.tertiaryBtn:hover {
  color: #d12828;
}

/*Warning buttons*/
.warningBtn {
  font-weight: 500;
  color: #ffffff;
  background-color: #d12828;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.warningBtn:hover {
  color: #ffffff;
  background-color: #ad020c;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.warningBtnLessAttention {
  font-weight: 500;
  color: #d12828;
  padding: 7px 20px;
  background-color: #ffffff;
  border-radius: 5px;
  border: none;
}

.warningBtnLessAttention:hover {
  color: #ffffff;
  background-color: #d12828;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.btn-outline-danger,
.btn-outline-danger:disabled {
  color: #ffffff;
  background-color: #d12828;
  border: none;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.btn-outline-success {
  background-color: #FFFFFF;
}

/*------------------------------------------------------------------
              Custom non-boostrap button styles
------------------------------------------------------------------*/

.custom-dropdown-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 25px;
  width: 25px;
  background-color: #f5f6f7;
  border: none;
  border-radius: 3px;
  box-shadow: 0px 1.5px 3px 0.2px rgb(0 0 0 / 22%);
}

.custom-dropdown-menu {
  position: absolute;
  z-index: 1;
  right: 10px;
  background-color: #FFFF;
  box-shadow: 0px 3px 8px 1.5px rgb(0 0 0 / 20%);
  border-radius: 5px;
}

.custom-dropdown-option {
  display: block;
  padding: 6px 15px;
  cursor: pointer;
  font-weight: 600;
}

.custom-dropdown-option:hover {
  background-color: #ECEDF0;
}

.custom-dropdown-option:first-child {
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
}

.custom-dropdown-option:last-child {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dropdownBtn {
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ced4da;
  background-color: #FFFF;
  border-radius: 5px;
  color: #707070;
  font-weight: 600;
  cursor: default !important;
  white-space: nowrap;
  overflow: hidden;
}

.dropdownBtn-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.dropdownBtn:focus,
.dropdownBtn:active,
.open .dropdownBtn {
  box-shadow: 0 0 0 0.2rem rgba(78, 78, 78, 0.2) !important;
  border-color: #80bdff;
}

/* Page specific button classes */

.statisticsDropdownFilterBtn {
  box-shadow: none;
  border: 1px solid #ced4da;
  color: #707070;
}

.statisticsBtn,
.statisticsDropdownFilterBtn {
  font-size: 16px !important;
  font-weight: 500 !important;
  border-radius: 5px !important;
}

.statisticsDropdownFilterBtn {
  padding: 5px 20px !important;
}

.statisticsBtn {
  padding: 6px 20px !important;
}

.statisticsBtn:focus,
.statisticsBtn:active,
.statisticsDropdownFilterBtn:focus,
.statisticsDropdownFilterBtn:active,
.open .statisticsDropdownFilterBtn {
  box-shadow: 0 0 0 0.2rem rgba(78, 78, 78, 0.2) !important;
  border-color: #80bdff;
}

/**/
/* .btn-primary:active:focus:not([disabled]),
.btn-primary.active:focus:not([disabled]),
.btn-primary:focus:not([disabled]) {
    box-shadow: 0 0 0 .2rem rgba(18, 65, 205, 0.5) !important;
} */

.btn-outline-primary:focus:not([disabled]),
.form-control:focus:not([disabled]) {
  box-shadow: 0 0 0 0.2rem rgba(78, 78, 78, 0.2) !important;
  /*border-color: #1D83E9 !important;*/
}

.btn-light {
  background-color: white;
}

.btn-link,
.btn-link:hover {
  color: #1d83e9;
}

.btn[disabled] {
  cursor: not-allowed;
}

.badge-danger {
  background-color: #ad020c;
}

.border-primary {
  border-color: #1241cd !important;
}

.border-secondary {
  border-color: #dadada !important;
}

.text-danger,
.border-danger {
  color: #ad020c !important;
}

.text-success {
  color: #089c3b !important;
}

.text-warning {
  color: #feac0b !important;
}

.input-group-text {
  border-radius: 0px;
}

.clearfix {
  clear: both;
}

.input-group-append {
  margin-left: 6px;
}

/*------------------------------------------------------------------
              Custom notification badge styles
------------------------------------------------------------------*/

.matNotificationBadge div p {
  padding: 2px 5px 4px 5px !important;
}

/*------------------------------------------------------------------
            Ngx multiselect dropdown style fixes  
------------------------------------------------------------------*/

ngx-bootstrap-multiselect .search-container>.input-group-append>.btn {
  margin-left: 6px;
}

ngx-bootstrap-multiselect .search-container>.input-group-prepend>.input-group-text {
  border-radius: 0.4rem !important;
}

ngx-bootstrap-multiselect .search-container>.input-group-prepend {
  margin-right: 6px;
}

/*------------------------------------------------------------------
        Custom styles for the ngx-bootstrap-multiselect 
------------------------------------------------------------------*/

.ssDropdownToLeft .dropdown-menu {
  right: 1px !important;
  left: -205px !important;
}

.ssDropUp .dropdown-menu {
  bottom: 41px !important;
  top: auto !important;
  height: 350px !important;
}

.ssPlusIcon:before {
  content: "\f067";
  font-family: "FontAwesome";
  margin-right: 7px;
}

.ssDropupIcon .dropdown-toggle:after {
  transform: rotate(180deg);
}

.careRecipientCardFormDropdownMenu .dropdown-menu {
  height: 900% !important;
  width: 100% !important;
}

.graySSBtn {
  font-weight: 500;
  color: #ffffff;
  background-color: #5a6268 !important;
  border-radius: 5px;
  border: none;
  padding: 7px 20px;
  box-shadow: 0px 1px 2px rgba(9 30 66 / 15%);
}

.graySSBtn:hover {
  color: #ffffff;
  background-color: #424a52 !important;
}

/* Button disabled styles */
ngx-bootstrap-multiselect[ng-reflect-disabled=true] div .dropdownBtn {
  background-color: #e9ecef;
}

ngx-bootstrap-multiselect[ng-reflect-disabled=true] div .dropdownBtn:active,
ngx-bootstrap-multiselect[ng-reflect-disabled=true] div .dropdownBtn:focus {
  box-shadow: none !important;
  border-color: #ced4da;
}


/**Fixes for Bootstrap 3 classes **/

.input-group-addon,
.input-group-btn {
  display: none;
}

ngx-bootstrap-multiselect .dropdown-item.active,
ngx-bootstrap-multiselect .dropdown-item:active {
  color: #000;
  background-color: #f4f4f4;
}

/* Custom checkbox */
.custom-checkbox {
  line-height: 1.1;
  display: grid;
  grid-template-columns: 1em auto;
  gap: 0.5em;
  pointer-events: none;
}

/* Custom unchecked styles */
.custom-checkbox input[type="checkbox"] {
  -webkit-appearance: none;
  appearance: none;
  background-color: inherit;
  margin: 0;
  background-color: #FFFF;
  margin: 0;
  font: inherit;
  color: #bfbfbf;
  width: 1.15em;
  height: 1.15em;
  border: 1.8px solid #bfbfbf;
  border-radius: 0.15em;
  transform: translateY(-0.075em) scale(0.8);
  display: grid;
  place-content: center;
  pointer-events: all;
}

.custom-checkbox+.custom-checkbox {
  margin-top: 1em;
}

.custom-checkbox input[type="checkbox"]::before {
  content: "";
  width: 0.7em;
  height: 0.6em;
  transform: scale(0);
  transition: 120ms transform ease-in-out;
  box-shadow: inset 1em 1em #FFFF;
  transform-origin: bottom left;
  clip-path: polygon(14% 40%, 0 63%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}

.custom-checkbox input[type="checkbox"]:checked::before {
  transform: scale(1);
}

.custom-checkbox input[type="checkbox"]:checked {
  background-color: #1241cd;
  border-color: #1241cd;
}

.custom-checkbox input[type="checkbox"]:active {
  background-color: #1d75ff;
  border-color: #1d75ff;
}

.custom-checkbox input[type="checkbox"]:disabled {
  background-color: #E5E5E5;
  border-color: #bfbfbf;
  cursor: not-allowed;
}

/* SWITCH */
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input {
  display: none;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #707070;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: #FFFF;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked+.slider {
  background-color: #1241CD;
}

input:focus+.slider {
  box-shadow: 0 0 1px #1241CD;
}

input:checked+.slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

input:disabled+.slider:before {
  background-color: #DADADA;
}

input:disabled+.slider {
  cursor: default;
}

input:disabled:checked+.slider {
  background-color: #07184D;
}

.cmn-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}

.cmn-toggle+label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  user-select: none;
}

input.cmn-toggle-flat+label {
  padding: 2px;
  width: 50px;
  height: 25px;
  background-color: #dddddd;
  transition: background 0.4s;
}

mat-slide-toggle.mat-checked .mat-slide-toggle-thumb,
mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #1d83e9 !important;
  transition-duration: 0.4s;
}

.mat-ripple-element {
  background-color: rgba(86, 151, 171, 0.15) !important;
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: #1d83e9 !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: #1d83e9 !important;
}

input.cmn-toggle-flat+label:before,
input.cmn-toggle-flat+label:after {
  display: block;
  position: absolute;
  content: "";
}

input.cmn-toggle-flat+label:before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  transition: background 0.4s;
}

input.cmn-toggle-flat+label:after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 21px;
  background-color: #dddddd;
  transition: margin 0.4s, background 0.4s;
}

input.cmn-toggle-flat:checked+label {
  background-color: #1d83e9;
}

input.cmn-toggle-flat:checked+label:after {
  margin-left: 21px;
  background-color: #1d83e9;
}

mat-form-field .mat-form-field-underline {
  background-color: #1241cd !important;
}

mat-form-field.mat-focused .mat-form-field-ripple {
  background-color: #1d83e9 !important;
}

mat-form-field.mat-focused .mat-form-field-label {
  color: #1d83e9 !important;
}

.mat-calendar-body-selected {
  background-color: #1d83e9 !important;
}

.mat-calendar-body-today:not(.mat-calendar-body-selected) {
  border-color: #1d83e9;
}

.mat-datepicker-toggle:active,
.mat-datepicker-toggle-active {
  color: #1d83e9 !important;
}

.mat-menu-panel {
  margin-top: 12px;
  border: 1px solid lightgrey;
}

.mat-pseudo-checkbox-checked {
  background-color: #1d83e9 !important;
}

/*------------------------------------------------------------------
              Angular Material 15 style fixes        
------------------------------------------------------------------*/

/* Changes checked mat options text color */
mat-option.mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled) .mdc-list-item__primary-text {
  color: #1241CD !important;
}

@media all and (max-width: 990px) {
  .stepperContainer>.mat-horizontal-content-container {
    padding: 0 !important;
  }
}

@media all and (max-width: 990px) {

  .stepperContainer>.mat-horizontal-stepper-header-container>.mat-step-header .mat-step-label,
  .mat-step-header .mat-step-optional {
    display: none !important;
  }
}

.serviceTimeInput>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix {
  border: none !important;
  padding-bottom: 0.15em !important;
  padding-top: 0.26em !important;
}

.serviceTimeInput {
  text-align: center !important;
  width: 20% !important;
}

.device-alarm-route-select>.mat-form-field-wrapper>.mat-form-field-flex>.mat-form-field-infix {
  border: none !important;
  padding-bottom: 0.15em !important;
  padding-top: 0.26em !important;
  width: 120px;
}

.mat-tooltip {
  font-size: 0.8em !important;
}

.vertical-spacer {
  height: 1em;
}

/* Vertical ruler */
.vr {
  border-right: 1px solid lightgray;
  width: 1px;
  height: auto;
  margin: 0px 10px;
}

/* Horizontal ruler */
/* Use when <hr> cannot be used */
.divider {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  margin: 1em 1em 2em 1em;
}

.capitalize {
  text-transform: capitalize;
}

.mat-drawer-inner-container {
  overflow: visible !important;
}

/*Styles for the card elements which are using angular-material's mat-grid-list components*/
.mat-grid-card-content {
  border-radius: 0.4rem !important;
  background-color: #fff;
  box-shadow: -4px 4px 8px 0px rgb(0 0 0 / 30%);
}

.mat-grid-card-dark {
  padding: 0 25px 25px 25px;
  background-color: rgba(0, 0, 0, 0.06);
  border: 1px solid #ced4da;
  border-radius: 5px;
  padding-top: 5px;
}

.mat-grid-card-header {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.mat-grid-card-header-blue {
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
  background-color: rgb(221, 231, 240);
}

.mat-grid-card-container {
  border-radius: 5px !important;
  margin: auto;
  height: fit-content;
  width: 98%;
  margin-top: 15px;
}

.card-header-blue {
  background-color: rgb(221, 231, 240);
  border-top-left-radius: 5px !important;
  border-top-right-radius: 5px !important;
}

.rounded-bottom-corners {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-top-corners {
  border-bottom-left-radius: 5px !important;
  border-bottom-right-radius: 5px !important;
}

.rounded-corners {
  border-radius: 5px !important;
}

.blue-text {
  color: #07184d;
}

.savingFailed.mat-snack-bar-container {
  background: white !important;
  border: #f1bebe 2px solid;
}

.savingFailed .mat-simple-snackbar {
  background: white !important;
  color: #ad020c;
  justify-content: center !important;
}

/*Typography*/
.mainDarkBlueText {
  color: #07184D
}

.normalFontWeight {
  font-weight: normal;
}

ngb-modal-backdrop {
  z-index: 1050 !important
}

.negative-highlight {
  background-color: #ffdee8;
}

.positive-highlight {
  background-color: #d7ebdc;
}

.warning-highlight {
  background-color: #fdf8e7;
}

.positive-highlight,
.negative-highlight,
.warning-highlight {
  padding: 1px 10px;
  border-radius: 5px;
  word-wrap: break-word;
  display: block;
}

.line-through {
  text-decoration: line-through;
  text-decoration-thickness: 2px;
}

/* Container styles */
.bordered-box {
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  padding: 10px;
}

/*----------------------------------------------------------
        Fixes for the CDK overlay and mat-dialog
----------------------------------------------------------*/

/*
  Makes CDK overlay scrollable. Needed for Mat-Dialogs to enable overlay scrolling when content is too long.
*/
.cdk-global-overlay-wrapper {
  display: flex;
  position: absolute;
  z-index: 1000;
  overflow: auto;
  pointer-events: auto !important;
}

/*
 Creates empty space at the bottom of the Mat-Dialog 
*/
.mat-mdc-dialog-container {
  padding-bottom: 50px;
}